import { createContext, useState, useCallback } from 'react';
import { InlineFeedbackProps } from '@hawkins/components';

type VariantType = InlineFeedbackProps['variant'];
interface FeedbackState {
  message: string | null;
  variant?: VariantType;
  addFeedbackRegular: (message: string) => void;
  addFeedbackSuccess: (message: string) => void;
  addFeedbackWarning: (message: string) => void;
  addFeedbackError: (message: string) => void;
  addFeedbackErrorButton: (message: string) => void;
  removeFeedback: () => void;
}

const defaultFeedbackContext: FeedbackState = {
  message: null,
  variant: undefined,
  addFeedbackRegular: () => null,
  addFeedbackSuccess: () => null,
  addFeedbackWarning: () => null,
  addFeedbackError: () => null,
  addFeedbackErrorButton: () => null,
  removeFeedback: () => null,
};

export const GlobalFeedbackContext = createContext<FeedbackState>(
  defaultFeedbackContext
);

export function GlobalFeedbackProvider({ children }: { children: any }) {
  const [message, setMessage] = useState<string | null>(null);
  const [variant, setVariant] = useState<VariantType>('success');

  const removeFeedback = () => {
    setMessage(null);
  };
  const addFeedbackRegular = (message: string) => {
    setVariant('regular');
    setMessage(message);
  };
  const addFeedbackSuccess = (message: string) => {
    setVariant('success');
    setMessage(message);
  };
  const addFeedbackWarning = (message: string) => {
    setVariant('warning');
    setMessage(message);
  };
  const addFeedbackError = (message: string) => {
    setVariant('error');
    setMessage(message);
  };

  const contextValue = {
    message: message,
    variant: variant,
    addFeedbackRegular: useCallback(addFeedbackRegular, []),
    addFeedbackSuccess: useCallback(addFeedbackSuccess, []),
    addFeedbackWarning: useCallback(addFeedbackWarning, []),
    addFeedbackError: useCallback(addFeedbackError, []),
    addFeedbackErrorButton: useCallback(addFeedbackError, []),
    removeFeedback: useCallback(() => removeFeedback(), []),
  };

  return (
    <GlobalFeedbackContext.Provider value={contextValue}>
      {children}
    </GlobalFeedbackContext.Provider>
  );
}
